import React from "react";
// import { graphql } from "gatsby";

import Layout from "../components/layout";

import SEO from "../components/seo";
// import styled from "styled-components";
// import gorilla from "../images/gorilla.svg";
import { Panel } from "./index";
// import { login, logout, isAuthenticated, getProfile } from "../utils/auth";

// const Content = styled.div`
//   padding: 3rem;
// `;
// const SNLWrapper = styled.div`
//   display: flex;
//   flex-direction: row;
//   gap: 30px;
//   flex-wrap: wrap;
// `;
// const Form = styled.form`
//   box-sizing: border-box;
//   display: flex;
//   flex-direction: column;
//   margin: auto;
//   gap: 30px;
//   flex-grow: 4;
//   min-width: 50vw;
//   width: 40%;
//   /* width: 100%; */
// `;
// const Input = styled.input`
//   background: transparent;
//   border: none;
//   border-bottom: 1px solid #e75b95;
//   font-size: 1rem;
//   /* width: 70vw; */
//   color: #f4ecd2;
//   direction: rtl;
//   ::placeholder {
//     color: #f4ecd2;
//   }
//   :focus {
//     outline: none;
//   }
// `;
// const TextField = styled.textarea`
//   background: transparent;
//   border: none;
//   border-bottom: 1px solid #e75b95;
//   font-size: 1rem;
//   color: #f4ecd2;
//   direction: rtl;
//   ::placeholder {
//     color: #f4ecd2;
//   }
//   :focus {
//     outline: none;
//   }
// `;
// const Button = styled.button`
//   height: 50px;
//   font-size: 1rem;
//   background: #e75b95;
//   box-shadow: none;
//   border: none;
//   color: #f4ecd2;
// `;
// const Img = styled.img`
//   max-width: 35vw;
//   margin: auto;

//   margin-top: 0;
// `;
// const Logout = styled.div`
//   /* margin-left: auto; */
//   display: flex;
// `;
// const UserWrap = styled.div`
//   font-size: 1rem;
//   display: flex;
//   place-items: center;
//   img {
//     width: 50px;
//   }
//   display: flex;
//   flex-direction: column;

//   width: fit-content;
//   place-items: center;
//   font-weight: bold;
//   margin-left: auto;
//   margin-top: 4rem;
//   font-weight: 900;
//   color: #e75b95;
//   cursor: pointer;
// `;
// const Agree = styled.span`
//   margin: 1rem;
// `;
// const CheckMark = styled.span`
//   position: absolute;
//   /* top: 0;
//   left: 0; */
//   height: 25px;
//   width: 25px;
//   background-color: transparent;
//   border: 1px solid #f4ecd2; /* display: block; */
//   /* On mouse-over, add a grey background color */

//   /* Create the checkmark/indicator (hidden when not checked) */
//   ::after {
//     content: "";
//     position: absolute;
//     display: none;
//   }

//   /* Style the checkmark/indicator */
//   ::after {
//     left: 9px;
//     top: 3px;
//     width: 7px;
//     height: 17px;
//     border: solid #f4ecd2;
//     border-width: 0 3px 3px 0;
//     -webkit-transform: rotate(45deg);
//     -ms-transform: rotate(45deg);
//     transform: rotate(45deg);
//   }
// `;
// const Checkbox = styled.input`
//   /* When the checkbox is checked, add a blue background */
//   :checked ~ ${CheckMark} {
//     background-color: #e75b95;
//   }
//   /* Show the checkmark when checked */
//   :checked ~ ${CheckMark}:after {
//     display: block;
//   }
// `;
// const CheckboxWrapper = styled.div`
//   ${Checkbox} {
//     position: absolute;
//     opacity: 0;
//     cursor: pointer;
//     height: 0;
//     width: 0;
//   }
// `;
// const Rules = styled.a`
//   cursor: pointer;
//   color: #f4ecd2;

//   :hover {
//     color: #e75b95;
//   }
// `;
const SNL = () => {
  // const [title, setTitle] = React.useState("");
  // const [idea, setIdea] = React.useState("");
  // const [script, setScript] = React.useState("");
  // const [mobile, setMobile] = React.useState("");
  // const [email, setEmail] = React.useState("");

  // const [buttonText, setButtonText] = React.useState("ابعت");

  // if (!isAuthenticated()) {
  //   login();
  //   return <p>Redirecting to login...</p>;
  // }
  // const user = getProfile();
  // console.log(isAuthenticated(), user);

  // const handleSubmit = async (e) => {
  //   setButtonText("Sending...");
  //   const form = {
  //     email: user.email,
  //     name: user.nickname,
  //     mobile,
  //     title,
  //     idea,
  //     script,
  //   };
  //   const scriptURL =
  //     "https://script.google.com/macros/s/AKfycbwLfeRc8kang_HifjLc0DKwc-qzTPfkODUmLcgb-qyA9OTJyOxY/exec";
  //   e.preventDefault();
  //   let formdata = new FormData();
  //   formdata.append("email", form.email);
  //   formdata.append("name", form.name);
  //   formdata.append("mobile", form.mobile);

  //   formdata.append("title", form.title);
  //   formdata.append("idea", form.idea);
  //   formdata.append("script", form.script);

  //   fetch(scriptURL, { method: "POST", body: formdata })
  //     .then((response) => {
  //       console.log("Success!");
  //       setButtonText(" وصل وجاري القراءة! ");
  //     })
  //     .catch((error) => {
  //       console.error("Error!", error.message);
  //       setButtonText("Please try again");
  //     });
  // };

  return (
    <Layout>
      <SEO title="SNL" keywords={[`bingbong`, "snl"]} />

      <Panel color={"#231f20"} fontColor={"#f4ecd2"}>
        {/* <Content>
          {isAuthenticated() ? (
            <>
              <SNLWrapper>
                <Img src="/snl-logo.png" />

                <Form name="submit-to-google-sheet" onSubmit={handleSubmit}>
                  <Input
                    name="title"
                    type="text"
                    placeholder=" عنوان الاسكتش "
                    onChange={(e) => {
                      setTitle(e.currentTarget.value);
                    }}
                    required
                  />
                  <Input
                    name="idea"
                    type="text"
                    placeholder="فكرة السكتش في سطر"
                    onChange={(e) => setIdea(e.currentTarget.value)}
                    required
                  />
                  <Input
                    name="mobile"
                    type="tel"
                    placeholder="موبايلك"
                    required
                    onChange={(e) => setMobile(e.currentTarget.value)}
                  />
                  <TextField
                    name="script"
                    placeholder="الاسكتش الكامل"
                    onChange={(e) => setScript(e.currentTarget.value)}
                    rows={10}
                    required
                  />
                  <CheckboxWrapper>
                    <p>
                      أقر أنا مقدم العمل المعرف ب ( الاسكتش ) بأني أوافق على أن
                      لا تتحمل شركة بينج بونج للاستشارات الفنية أو برنامج SNL
                      بالعربي أو الشركة المنتجة له أو الوسيط العارض له
                      تليفزيونيا أو رقميا أي مسئولية قانونية واتنازل أنا صاحب
                      العمل المقدم عن أي حقوق مادية أو معنوية لها علاقة به عدا
                      في حالة استخدام العمل المقدم بكامل صيغته وموضوعه وتفاصيله
                      المرسلة عبر هذا الموقع بشكل تجاري في أي من الوسائط
                      الإعلامية الحالية او المستحدثة فقط بواسطة شركة بينج بونج
                      للإستشارات الفنية أو برنامج SNL بالعربي. وأنه لا تتحمل
                      بينج بونج للإستشارات الفنية ولا برنامج SNL بالعربي أو
                      الشركة المنتجة للبرنامج أو المنصات والوسائط العارضة له أي
                      مسئولية قانونية أو حقوق مادية أو معنوية في حالة استخدام
                      العمل بواسطة شركة أخري أو برنامج آخر أو منصة عرض أخري عدا
                      المذكور أعلاه. وتختص المحاكم المصرية في مدينة القاهرة
                      بالنظر في أي منازعات لها علاقة بهذا البند.{" "}
                    </p>
                    <label class="container">
                       
                      <Agree>
                        {" "}
                        <Rules href="#rulespopup">
                          {" "}
                          اوافق علي الشروط والأحكام
                        </Rules>
                      </Agree>
                      <Checkbox type="checkbox" required />
                      <CheckMark class="checkmark" />
                    </label>
                  </CheckboxWrapper>
                  <div id="rulespopup" class="overlay">
                    <div class="popup">
                      <h2>Here i am</h2>
                      <a class="close" href="#">
                        &times;
                      </a>
                      <div class="content">
                        Thank to pop me out of that button, but now i'm done so
                        you can close this window.
                      </div>
                    </div>
                  </div>
                  <Button type="submit">{buttonText}</Button>
                </Form>
              </SNLWrapper>
            </>
          ) : (
            <p>invites only.</p>
          )}

          <UserWrap>
            <span>
              <img src={gorilla} />
            </span>
            <span>{user.nickname}</span>
            <Logout
              onClick={(e) => {
                logout();
                e.preventDefault();
              }}
            >
              LOGOUT?
            </Logout>
          </UserWrap>
        </Content> */}
      </Panel>
    </Layout>
  );
};

export default SNL;
